import React from "react"

import { graphql, useStaticQuery } from "gatsby"
import { Section, Icon, Banner } from "../components/elements"
import styled from "styled-components"
import {
  ButtonA,
  Grid,
  Col,
  Color,
  AlignCenter,
  Media,
} from "../components/utilities"

import { Layout } from "../components/layouts/Layout"

import balanceGold from "../images/balance-gold.png"

export const getFileNode = ({ edges, name }) =>
  edges.find(item => item.node.relativePath == name)

const firm = ({ edges }) => [
  {
    image: getFileNode({ edges, name: "firm.jpg" }).node.childImageSharp.fluid
      .src,
  },
]

const OrangeBar = styled.div`
  height: 3px;
  width: 40px;
  background-color: ${Color.orange};
  margin-bottom: 1rem;
`

const ImgCol = styled.div`
  display: grid;
  grid-row-gap: 2rem;
  img {
    border-radius: 5px;
    width: 90%;
  }
  ${Media.below.tablet`
    display: none;
  `}
`

const AboutPage = () => {
  const { allFile } = useStaticQuery(graphql`
    query PortfolioQuery {
      allFile(filter: { relativePath: { in: ["firm.jpg", "firm2.jpg"] } }) {
        edges {
          node {
            relativePath
            childImageSharp {
              fluid(maxWidth: 1200) {
                src
              }
            }
          }
        }
      }
    }
  `)
  return (
    <Layout title="About Pfost Law, Ltd.">
      <Banner title="About Pfost Law, Ltd." />
      <Section>
        <Grid>
          <AlignCenter>
            <ImgCol>
              {firm({ edges: allFile.edges }).map(item => (
                <img src={item.image} />
              ))}
            </ImgCol>
          </AlignCenter>
          <Col>
            <Icon image={balanceGold} />
            <h2>
              Small Scale Law Firm That Focuses
              <br />
              On Quality &amp; Timeliness For <br />
              Our Clients
            </h2>
            <OrangeBar />
            <p>
              In 2020, attorney Sara E. Pfost established Pfost Law, Ltd. with
              the goal of providing high quality and personalized legal services
              to individuals, families, and businesses throughout Ohio and
              Michigan. Ms. Pfost offers clients extensive experience in the
              areas of Estate Planning, Probate and Trust Administration, Real
              Estate Transactions, and Business Law as well as an unwavering
              dedication to client satisfaction. <br />
              <br />
              Pfost Law, Ltd. is fortunate to have attorney Brian D. Smith acting in an of counsel capacity.
              Mr. Smith has been practicing law since 2008, has received the
              honor of Super Lawyer, and brings with him significant experience
              in the areas of Family Law, Civil Litigation, and
              Probate and Trust Administrations. <br />
              <br />
              Pfost Law’s mission is to serve each client with the utmost care
              and compassion.
            </p>
            <br />
            <br />
            {/* <Grid>
              <Col>
                <ul>
                  <li>Innovative Law Solutions</li>
                  <li>Fair Business Climate</li>
                  <li>Experienced & Best Advisors</li>
                  <li>Smarter Global Service</li>
                </ul>
              </Col>
              <Col>
                <ul>
                  <li>Quick Legal Notices</li>
                  <li>Protection of Properties</li>
                  <li>Simple Law Assessments</li>
                </ul>
              </Col>
            </Grid>
            <br /> */}
            <ButtonA to="/contact" modifiers={["primaryOutline"]}>
              Get Started Now
            </ButtonA>
          </Col>
        </Grid>
      </Section>
    </Layout>
  )
}

export default AboutPage
